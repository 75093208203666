import React, { Component } from 'react';
import Slider from "react-slick";
import { Container, Image } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import TestimonialBg from '../../assets/images/testimonials-bg.jpg';
import './testimonials.scss';


class Testimonials extends Component {
  render(){
    var FeatureSettings = {
      dots: false,
      arrows: true,
      autoplay: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
    };

    return (
    <div className="testimonialHolder">
      <Container>
        <div className="testimonialSec">
        <div className="testimonialContent">
          <div className="testimonialBg">
            <Image src={TestimonialBg} alt="TestimonialBg"></Image>
          </div>
          <div className="testimonialContentSp">
          <h4>WHAT THEY SAY</h4>
          <p>WizTech is often regarded as a company that cares as much about the projects they win as the customers
themselves. WizTech strives to be “your partners in excellence”. We look forward to work with you, listening,
learning and giving back an excellent service!</p>
          </div>
        </div>
        <div className="testimonialSlider">
          <Slider {...FeatureSettings}><div className="item">
              <div className="testimonialBlockSp">
                <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="2000">
                  <div className="testimonialBlock">
                    <p>“WizTech aims to please. Given a short time frame they were able to deliver as high quality of a product
as possible. On this project, they went beyond my requirements and did an excellent job.
They did their best to match my requirement and put all their efforts to achieve this project.
Higly recommended.”
<span>mylanguagetravel.com</span>
</p>
                  </div>
                </div>
                <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="700">
                  <div className="testimonialBlock">
                    <p>“Amazing job done by WizTech and his crew! I've used them many times...and will use over and over
again. They are trustworthy and always does an amazing job. I've tried many companies, but none do as
great of a job as WizTech. ”
<span>monogramcustompools.com / monogramcustomhomes.com</span>
</p>
                  </div>
                </div>
                <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="700">
                  <div className="testimonialBlock">
                    <p>“WizTech has never let me down. I'm hiring them again for another job immediately. They aim to please.
Given a short time frame they were able to deliver as high quality of a product as possible. ”
<span>ktcmgmt.com /lavadesk.com</span>
</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="testimonialBlockSp full-width">

              <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="1500">
                  <div className="testimonialBlock">
                    <p>“WizTech took on our HUGE project and delivered it after six months of hard work and bug testing.
This team stuck by us after what must be near 100 revisions to the website, code, and various issues.
We've worked with WizTech before on another project and knew that they were the right guys for this
job as well. All I can say is if you have a PHP/MySQL project for development, I highly recommend
WizTech. They will get the job done and they are very courteous with bug fixes and complaints (if any).
For those worried about working with WizTech and language barriers, don't be! Saami's English skills are
VERY good overall and as only gotten better over the years we've worked with them. WizTech, thank you
once again for the job well done!”
<span>topfiveawards.com / customerhostingreviews.com</span>
</p>
                  </div>
                </div>
                <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="2000">
                  <div className="testimonialBlock">
                    <p>“These guys are a class act. Not only they have the brain power and talent but also they have the
professional attitude required to perform complicated projects. Feel safe to hire them. They are the real
deal!”
<span>mycellforce.com</span>
</p>
                  </div>
                </div>
              </div>
            </div>
             <div className="item">
              <div className="testimonialBlockSp">
                <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="2000">
                  <div className="testimonialBlock">
                    <p>“I really enjoy working with this contractor. I have many projects going on at one time and I like when I
work with a contractor that does a lot of work with minimal direction. Of course, the more specific I am
about what I want the closer they get to the target. And if I don't know exactly what I want they will step up
to the plate and come up with their own ideas and most the time they get it right the first time.”
<span>stevehatchracing.com</span>
</p>
                  </div>
                </div>

                <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="1500">
                  <div className="testimonialBlock">
                    <p>“WizTech is a very professional organization that sticks to its timelines and has a very strong ability to
work with its customer to try and find the best solution for you. Great group of people and a great team
to work with and are always available when you need them I highly recommend this team”
<span>Serge</span>
</p>
                  </div>
                </div>
                <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="700">
                  <div className="testimonialBlock">
                    <p>“A true professional and provides quality work and adheres to all deadlines. I would highly
recommend him for any future development.”
<span>imperialbag.com</span>
</p>
                  </div>
                </div>

                {/* <div className="testimonialBlockFlex" data-aos="fade-up" data-aos-duration="1500">
                  <div className="testimonialBlock">
                    <p>“WizTech is a very professional organization that sticks to its timelines and has a very strong ability to
work with its customer to try and find the best solution for you. Great group of people and a great team
to work with and are always available when you need them I highly recommend this team”</p>
                  </div>
                </div> */}
              </div>
            </div>

           </Slider>
        </div>
        </div>
      </Container>
    </div>
  );
  }

}

export default Testimonials;
