import React, { Component } from 'react';

import './career.scss';

/* eslint-disable-next-line */
export interface CareerProps {}

class Career extends Component {
  render(){
    return (
    <div className="careerContainer">
      <div className="container">
        <div className="subContainer">
        <div className="careerHolder">
        <h4>If you are interested In joining us, feel free to send your resume</h4>
        <a href="mailto:jobs@wiztech.pk" className="btn btn-white">Send Your Resume</a>
        </div>
      </div>
      </div>
    </div>
  );
}

}
export default Career;
