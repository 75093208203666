import React, { Component } from 'react';

import { Link } from 'gatsby';
import { Image, ProgressBar } from 'react-bootstrap';
import CountUp from 'react-countup';

import SkillBanner1 from '../../assets/images/skill-banner.jpg';
import BgShape from '../../assets/images/bg-shape.svg';
import './skill.scss';

/* eslint-disable-next-line */
export interface SkillProps {}

class Skill extends Component {
  render(){
     return (
    <div className="skillHolder">
      <div className="skillContentLeftSec">
        <h4>Skills <br></br>&<br></br> Abilities</h4>
        <Image src={SkillBanner1} alt="Skill Banner"></Image>
      </div>
      <div id="skill" className="skillContentRightSec">
        <Image src={BgShape} alt="background"></Image>
        <div className="skillContentRightSp">
          <h4>19+ Year Of Experience</h4>
          <div className="skilProgress">
            <p><span>UI/UX Design</span><span><CountUp end={100} /></span></p>
            <ProgressBar now={100} />
          </div>
          <div className="skilProgress">
            <p><span>Website Design</span><span><CountUp end={100} /></span></p>
            <ProgressBar now={100} />
          </div>
          <div className="skilProgress">
            <p><span>Web DEvelopment</span><span><CountUp end={100} /></span></p>
            <ProgressBar now={100} />
          </div>
          <div className="skilProgress">
            <p><span>MOBILE APP DESIGN & DEVELOPMENT</span><span><CountUp end={100} /></span></p>
            <ProgressBar now={100} />
          </div>
          <div className="skilProgress">
            <p><span>DATABASE DEVELOPMENT & ADMINISTRATION</span><span><CountUp end={100} /></span></p>
            <ProgressBar now={100} />
          </div>
          <div className="skilProgress">
            <p><span>cloud data management</span><span><CountUp end={100} /></span></p>
            <ProgressBar now={100} />
          </div>
        </div>
      </div>
    </div>
  );
  }

}


export default Skill;
