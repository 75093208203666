import React, { Component } from 'react';
import Slider from "react-slick";
import {Image } from 'react-bootstrap';

import Project1 from '../../assets/images/project-1.jpg';
import Project2 from '../../assets/images/project-2.jpg';
import Project3 from '../../assets/images/project-3.jpg';
import Project4 from '../../assets/images/project-4.jpg';

import "slick-carousel/slick/slick.css";
import './featured-product.scss';

class FeaturedProduct extends Component {
  render(){
    var settings = {
      dots: false,
      arrows: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      variableWidth: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
          }
        }
      ]
    };
    return (
    <div className="featureProductHolder" id="work">
      <h4>FEATURED<br></br>PROJECTS</h4>
      <div className="featureProductSlider">
        <Slider {...settings}>
          <div className="item">
            <Image src={Project1} alt="feature project"></Image>
          </div>
          <div className="item">
            <Image src={Project2} alt="feature project"></Image>
          </div>
          <div className="item">
          <Image src={Project3} alt="feature project"></Image>
            </div>
          <div className="item">
            <Image src={Project4} alt="feature project"></Image>
          </div>
        </Slider>
      </div>
    </div>
  );
  }

}

export default FeaturedProduct;
