import React, { Component } from 'react';
import Slider from "react-slick";

import {Image } from 'react-bootstrap';

import Picnic01 from '../../assets/images/picnic/1.jpg';
import Picnic02 from '../../assets/images/picnic/2.jpg';
import Picnic03 from '../../assets/images/picnic/3.jpg';
import Picnic04 from '../../assets/images/picnic/4.jpg';
import Picnic05 from '../../assets/images/picnic/5.jpg';
import Picnic06 from '../../assets/images/picnic/6.jpg';
import Picnic07 from '../../assets/images/picnic/7.jpg';
import Picnic08 from '../../assets/images/picnic/8.jpg';
import Picnic09 from '../../assets/images/picnic/9.jpg';
import Picnic10 from '../../assets/images/picnic/10.jpg';

import Cricket01 from '../../assets/images/cricket/1.jpg';
import Cricket02 from '../../assets/images/cricket/2.jpg';
import Cricket03 from '../../assets/images/cricket/3.jpg';
import Cricket04 from '../../assets/images/cricket/4.jpg';
import Cricket05 from '../../assets/images/cricket/5.jpg';
import Cricket06 from '../../assets/images/cricket/6.jpg';
import Cricket07 from '../../assets/images/cricket/7.jpg';
import Cricket08 from '../../assets/images/cricket/8.jpg';
import Cricket09 from '../../assets/images/cricket/9.jpg';
import Cricket10 from '../../assets/images/cricket/10.jpg';
import Cricket11 from '../../assets/images/cricket/11.jpg';
import Cricket12 from '../../assets/images/cricket/12.jpg';
import Cricket13 from '../../assets/images/cricket/13.jpg';

import './event.scss';

class Event extends Component {
  render(){
    var match = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    var picnic = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
    <div className="container">
      <div className="eventHolder">
      <div className="subContainer text-center">
        <h4>Events</h4>
        <p>WizTech has offered many types of in-house co-curricular activities, which has been witnessed in developing the range of skills and attributes that are important for developers. This isn't a marketing exercise, but an approach that has been implemented for all employees’ to keep them in shape and develop their mindset skills</p>
      </div>
        <div className="row">
          <div className="col-md-6">
            <Slider {...match}>
            <div className="item">
              <Image src={Cricket01} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Cricket02} alt="feature project"></Image>
            </div>
            <div className="item">
            <Image src={Cricket03} alt="feature project"></Image>
              </div>
            <div className="item">
              <Image src={Cricket04} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Cricket05} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Cricket06} alt="feature project"></Image>
            </div>
            <div className="item">
            <Image src={Cricket07} alt="feature project"></Image>
              </div>
            <div className="item">
              <Image src={Cricket08} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Cricket09} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Cricket10} alt="feature project"></Image>
            </div>
            <div className="item">
            <Image src={Cricket11} alt="feature project"></Image>
              </div>
            <div className="item">
              <Image src={Cricket12} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Cricket13} alt="feature project"></Image>
            </div>
          </Slider>
          </div>
          <div className="col-md-6">
            <Slider {...picnic}>
            <div className="item">
              <Image src={Picnic01} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Picnic02} alt="feature project"></Image>
            </div>
            <div className="item">
            <Image src={Picnic03} alt="feature project"></Image>
              </div>
            <div className="item">
              <Image src={Picnic04} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Picnic05} alt="feature project"></Image>
            </div>
            <div className="item">
              <Image src={Picnic06} alt="feature project"></Image>
            </div>
            <div className="item">
            <Image src={Picnic07} alt="feature project"></Image>
              </div>
            <div className="item">
              <Image src={Picnic08} alt="feature project"></Image>
            </div>
            <div className="item">
            <Image src={Picnic09} alt="feature project"></Image>
              </div>
            <div className="item">
              <Image src={Picnic10} alt="feature project"></Image>
            </div>
          </Slider>
          </div>
        </div>
    </div>
    </div>
  );
  }

}

export default Event;
