import React, { Component } from 'react';
import Layout from '../layout';
import Banner from '../components/banner/banner';
import Header from '../components/header/header';
import About from '../components/about/about';
import Skill from '../components/skill/skill';
import Services from '../components/services/services';
import FeaturedProduct from '../components/featuredProduct/featured-product';
import Testimonials from '../components/testimonials/testimonials';
import Event from '../components/event/event';
import GetToWork from '../components/getToWork/get-to-work';
import PerksAndBenefits from '../components/PerksAndBenefits/perks-and-benefits';
import Career from '../components/Career/career';

import './index.scss';
import Footer from '../components/footer/footer';

class Index extends Component {

  render(){
     return (
    <Layout>
      <Banner></Banner>
      <Header></Header>
      <div className="AboutSkillHolder">
        <About></About>
        <Skill></Skill>
      </div>
      <Services></Services>
      <FeaturedProduct></FeaturedProduct>
      <PerksAndBenefits></PerksAndBenefits>
      <Testimonials></Testimonials>
      <Career></Career>
      <div className="eventWorkHolder">
        <Event></Event>
        <GetToWork></GetToWork>
      </div>
      <Footer></Footer>
    </Layout>
  );
  }

}

export default Index;
